<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="640px" :before-close="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
        label-width="130px">
        <el-form-item label="反馈内容：" prop="message">
          <el-input type="textarea" disabled v-model.trim="formData.message"
            :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
        <el-form-item label="回复内容：">
          <el-input type="textarea" :disabled="isShow" v-model.trim="message"
            :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button @click="onSub" v-if="!isShow" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import LyDialog from "../../../components/dialog/dialog";
import { addfeedback } from '@/api/api'
export default {
  emits: ['refreshData'],
  name: "addModule",
  components: { LyDialog },
  data () {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      peopleList: [],
      formData: {
        message: '',
        images: [],
      },
      rules: {
      },
      message: '',
      isShow: false
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.loadingSave = false
      this.$emit('refreshData')
    },
    addModuleFn (item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      this.formData = item ? item : {
        message: '',
        images: [],
      }
      if (this.formData.replies.length) {
        this.message = this.formData.replies[0].message
        this.isShow = true
      } else {
        this.message = ''
        this.isShow = false
      }
    },
    onSub () {
      if (!this.message) return
      this.loadingPage = true
      addfeedback({ feedback_id: this.formData.id, message: this.message }).then(res => {
        if (res.code == 2000) {
          this.dialogVisible = false
          this.loadingSave = false
          this.$emit('refreshData')
        }
      })
    }
  }
}
</script>
